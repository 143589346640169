import React from "react"
// import { Link } from 'gatsby';
// import { useSiteMetadata } from "../../hooks/use-site-metadata"
// Components
import Layout from '../../components/Layout';
import Pill from '../../components/Pill';


const ListingTagsPage = ({ pageContext: { sortedDistinctTagsArray,preferredTagsArray } }) => (
  <>
    <Layout>
      {/* <div class="flex flex-wrap content-center">
        These are my interests
</div> */}
      <div>
        <div><h2>Preferred Interests</h2></div>
        <div className="flex flex-row flex-wrap">
        {
          preferredTagsArray.filter((tag) => !(tag === 'publish-now')).map((tag) => {
            const tagLink = "/curious-about/" + tag;
            return (
              <Pill tagLink={tagLink} tagName={tag} />
            );
          })
        }
        </div>
      </div>
      <div >
        <div></div>
        <div><h2>Related Tags</h2></div>
        <div className="flex flex-row flex-wrap">
        {
          sortedDistinctTagsArray.filter((tag) => !(tag === 'publish-now')).map((tag) => {
            const tagLink = "/curious-about/" + tag;
            return (
              <Pill tagLink={tagLink} tagName={tag} />
            );
          })
        }
        </div>
      </div>
    </Layout >
  </>
);
export default ListingTagsPage;
