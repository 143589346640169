import React from 'react';
import { Link } from 'gatsby';

const Pill = ({ tagName, tagLink }) => {
    return (
        <Link to={tagLink}>
            <div
                className="text-white bg-gray-500 hover:bg-gray-300 hover:text-white active:bg-purple-600 font-bold
				text-sm text-center px-3 py-2 rounded outline-none focus:outline-none 
				 mr-1 mb-1 ease-linear transition-all duration-150"
            >
                <p className="mb-1">{tagName}</p>
            </div>
        </Link>
    )
}

export default Pill
